<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="品項名稱" prop="name">
        <BaseElInput v-model="formData.name" placeholder="請輸入" :disabled="disableForm" />
      </BaseElFormItem>
      <BaseElFormItem label="預估售價" prop="price">
        <BaseElInput v-model="formData.price" placeholder="請輸入" :disabled="disableForm" />
        <p class="mt-[8px] text-sm text-gray-80 leading-[17.38px]">
          建立訂閱方案時，您可參考各品項設定的預估金額，來制定該訂閱方案的金額
        </p>
      </BaseElFormItem>
      <BaseElFormItem label="品項說明" prop="description">
        <BaseElInput v-model="formData.description" type="textarea" rows="3" placeholder="請輸入" :disabled="disableForm" />
        <p class="mt-[8px] text-sm text-gray-80 leading-[17.38px]">
          品項組合被商城選做商品後，此說明文字將呈現在會員端，作為品項的說明文字
        </p>
      </BaseElFormItem>
    </BaseElForm>

    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="handleConfirm">
          確認
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { pubAptPaymentTypes } from '@/config/pubApt'
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import { get } from 'lodash'
import { noEmptyRules, integerRules } from '@/validation'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'EditSubscribeCombineItemDialog',
  components: {
  },
  props: {
    selectItem: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  setup (props, { emit }) {
    const formData = reactive({
      id: '',
      name: '',
      price: '',
      description: '',
    })
    const formRef = ref(null)
    const formRules = reactive({
      name: [noEmptyRules()],
      price: [integerRules()],
    })
    const disableForm = computed(() => (props.type === 'view'))
    const dialogTitle = computed(() => {
      let title
      if (props.type === 'create') title = '新增品項'
      if (props.type === 'edit') title = '編輯品項'
      if (props.type === 'view') title = '查看品項'
      return title
    })
    const handleConfirm = async () => {
      if (props.type === 'create') await createSubscribeEquityPack()
      if (props.type === 'edit') await updateSubscribeEquityPack()
      if (props.type === 'view') emit('close')
    }
    const createSubscribeEquityPack = async () => {
      const valid = await formUtils.checkForm(formRef.value)
      if (valid) {
        emit('update', 'create', formData)
        emit('close')
      }
    }

    const updateSubscribeEquityPack = async () => {
      const valid = await formUtils.checkForm(formRef.value)
      if (valid) {
        emit('update', 'edit', formData)
        emit('close')
      }
    }
    onMounted(async () => {
      if (props.selectItem) {
        Object.assign(formData, props.selectItem)
      }
    })
    return {
      get,
      handleConfirm,
      pubAptPaymentTypes,
      formData,
      formRules,
      disableForm,
      dialogTitle,
      formRef,
    }
  },
})
</script>

<style scoped lang="scss"></style>
