<template>
  <div class="reward-activity-info-block">
    <p class="card-title">基本資訊</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="是否有訂閱方案選用" prop="isSelect">
        <template slot="label">
          <FormItemTooltipLabel label="是否有訂閱方案選用" :tooltipWidth="200">
            <p>品項組合被訂閱方案選用後，不可編輯內容</p>
          </FormItemTooltipLabel>
        </template>
        <p>{{ formData.isSelect ? '是' : '否' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="品項組合名稱" prop="name">
        <p v-if="formData.isSelect">{{ formData.name }}</p>
        <BaseElInput v-else v-model="formData.name" placeholder="請輸入" />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { noEmptyRules } from '@/validation'
import { get } from 'lodash'

export default defineComponent({
  name: 'SubscribeInfoBlock',
  components: { FormItemTooltipLabel },
  props: ['FormsContext', 'combineData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      isSelect: null,
      name: null,
    })
    const formRules = {
      name: [noEmptyRules()],
    }

    const syncData = () => {
      const data = props.combineData
      formData.isSelect = !!get(data, 'planEquityPacks.length')
      formData.name = get(data, 'name')
    }

    const compactData = computed(() => {
      const data = {
        name: get(formData, 'name'),
      }
      return { ...data }
    })

    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('info', formRef.value)

      if (get(props.combineData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('info', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
    }
  },
})
</script>

  <style lang="postcss" scoped>

  </style>
