<template>
  <div class="external-transaction-activity-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="品項名稱" prop="name" align="center" />
      <BaseElTableColumn label="預估售價" prop="price" align="center" />
      <BaseElTableColumn label="品項說明" prop="description" align="center" />
      <BaseElTableColumn label="操作" align="center" fixed="right" width="140">
        <template slot-scope="scope">
          <TableEditBtnGroup
            copyBtn="查看"
            :hideCopy="!isSelect"
            :hideDelete="isSelect"
            :hideEdit="isSelect"
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
            @copy="onView(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteSubscribeCombineItem"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import dayjs from '@/lib/dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'

export default defineComponent({
  name: 'SubscribeCombineItemTable',
  components: { EmptyBlock, DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
    isSelect: { type: Boolean, default: false },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, name, price, description } = item

        return {
          id,
          name,
          price,
          description,
        }
      })
    })

    const deleteSubscribeCombineItem = async () => {
      emit('handleClickItem', 'delete', selectRow.value)
      modal.delete = false
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const onView = (row) => {
      emit('handleClickItem', 'view', row)
    }
    const onEdit = (row) => {
      emit('handleClickItem', 'edit', row)
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      onDelete,
      onView,
      deleteSubscribeCombineItem,
      rewardActivityTypeConfig,
    }
  },
})
</script>
